import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// tslint:disable:no-any

import {Ticket} from 'src/app/shared/models/ticket';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BreakpointObserver} from '@angular/cdk/layout';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {Subject} from 'rxjs/Rx';
import {DataService} from 'src/app/shared/services/data.service';
import {SelectedTicket} from 'src/app/shared/models/selected-ticket';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'app-ticket-details',
    templateUrl: './ticket-details.component.html',
    styleUrls: ['./ticket-details.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '*',
                opacity: 1,
            })),
            state('closed', style({
                height: '0',
                opacity: 0,
            })),
            transition('open => closed', [
                animate('0.35s'),
            ]),
            transition('closed => open', [
                animate('0.35s'),
            ]),
        ]),
    ],
})
export class TicketDetailsComponent implements OnInit {
    public ticketType: Ticket[];
    private selectedTickets: Ticket[];
    public ticketToBuy: SelectedTicket[];
    public today: Date;
    public inputChange = new Subject<any>();

    @Input() public form: FormGroup;
    @Input() public isTicket: boolean;
    @Input() public isSubscription: boolean;
    @Output() public notify = new EventEmitter<any>();

    constructor(private router: Router,
                private breakpointObserver: BreakpointObserver,
                private service: DataService) {
        this.today = new Date();
    }

    get isMobile() {
        return this.breakpointObserver.isMatched('(max-width: 768px)');
    }


    public ngOnInit(): void {
        this.ticketType = [];
        this.selectedTickets = [];
        this.ticketToBuy = [];

        if (this.router.url.toString().includes('abonamente')) {
            this.service.getTicketTypes('true').subscribe((response) => {
                response.map((r) => {
                    const ticket = new Ticket(r);
                    ticket._quantity = 0;

                    this.ticketType.push(ticket);
                });
            });

            } else {
            this.service.getTicketTypes('false').subscribe((response) => {
                response.map((t) => {
                    const ticket = new Ticket(t);
                    ticket._from = new Date();
                    const to = moment(new Date()).add(2, 'M').format('DD-MM-YYYY');
                    ticket._to =new Date(to);
                    this.ticketType.push(ticket);
                });
            });
        }
    }

    public displayPeriod(index): boolean {
        return this.isTicket && this.ticketType[index]._quantity && (this.ticketType[index]._quantity !== 0);
    }

    public increment(ticket: Ticket, index: number): void {
        this.ticketType[index] = this.updateQuantity(ticket, +(ticket._quantity ? ticket._quantity : 0) + 1);
        const sTicket = new SelectedTicket();
        sTicket._ticket = ticket;
        sTicket._type = ticket._id;
        this.ticketToBuy.push(sTicket);
        this.ticketToBuy = _.orderBy(this.ticketToBuy, ['type', 'asc']);

        this.notify.emit(this.ticketToBuy);
    }

    public decrement(ticket: Ticket, index: number): void {
        const qty = ticket._quantity > 0 ? ticket._quantity - 1 : 0;
        this.ticketType[index] = this.updateQuantity(ticket, qty);

        const sTicket = new SelectedTicket();
        sTicket._ticket = ticket;
        sTicket._type = ticket._id;
        const same = this.ticketToBuy.filter((t) => t._type === ticket._id);
        const diff = this.ticketToBuy.filter((t) => t._type !== ticket._id);
        same.splice(0,1);
        this.ticketToBuy = [];
        same.forEach((s) => this.ticketToBuy.push(s));
        diff.forEach((d) => this.ticketToBuy.push(d));
        this.ticketToBuy = _.orderBy(this.ticketToBuy, ['type', 'asc']);

        this.notify.emit(this.ticketToBuy);
    }

    public customTrackBy(index: number, ticket: Ticket): Ticket {
        return ticket;
    }

    private updateQuantity(ticket, qty): Ticket {
        const item = new Ticket(ticket);
        item._quantity = qty;

        return item;
    }

    public selectChangeFrom(ticket, index, event): void {
        this.ticketType[index] = this.updateDateFrom(ticket, event);
        this.notify.emit(this.ticketType);
    }

    public selectChangeTo(ticket, index, event): void {
        this.ticketType[index] = this.updateDateTo(ticket, event);
        this.notify.emit(this.ticketType);
    }

    private updateDateFrom(ticket, date): Ticket {
        const item = new Ticket(ticket);
        item._from = date;

        return item;
    }

    private updateDateTo(ticket, date): Ticket {
        const item = new Ticket(ticket);
        item._to = date;

        return item;
    }

    public updateQuantityOnChange(ticket, index, event): void {
        this.inputChange.pipe(
            debounceTime(500),
            distinctUntilChanged()).subscribe(() => {
            this.ticketType[index] = this.updateQuantity(ticket, event);
        });

        this.notify.emit(this.ticketType);
    }

    public onPaste(event): void {
        event.preventDefault();
    }

    public onKeyPress(event) {
        return (event.charCode !== 8 && event.charCode === 0 || (event.charCode >= 48 && event.charCode <= 57));
    }
}

