import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {

    public set(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public get(key: string): string {
        const item = localStorage.getItem(key);

        if (!item) {
            return null;
        }

        return item;
    }

    public delete(key: string): void {
        localStorage.removeItem(key);
    }

}
