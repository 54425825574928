import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {

    constructor(public snackBar: MatSnackBar,
                private translate: TranslateService) {
    }

    public showSuccess(message: string): void {
        this.translate.get(message.toUpperCase()).subscribe((response: string) => {
            this.snackBar.open(response, 'X', {duration: SharedConstants.SNACKBAR_TIME});
        });

    }

    public showError(message: string): void {
        this.translate.get(message.toUpperCase()).subscribe((response: string) => {
            this.snackBar.open(response, 'X', {
                panelClass: ['error'],
                duration: SharedConstants.SNACKBAR_TIME
            });
        });
    }
}
