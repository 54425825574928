<form #userForm="ngForm"
      (keyup.enter)="keyUpSubmit(userForm.valid)"
      autocomplete="off"
      (submit)="onSubmit(userForm)"
      class="asp-form asp-form-margin">
    <div fxFlex="column" fxFlexAlign="start center" class="asp-form asp-background-white">
        <div class="row asp-form" fxFlex="row" fxFlexAlign="start center">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12"
                         *ngIf="isSubscription && ticket._type === familySubscription">
                        <mat-form-field appearance="outline"
                                        class="asp-full-width">
                            <mat-label class="float-left">
                                {{'TICKET_DETAILS.ADULTS'| translate}}
                            </mat-label>
                            <input matInput
                                   type="number"
                                   name="adults"
                                   [(ngModel)]="ticket._adults"
                                   #adults="ngModel"
                                   id="adults"
                                   ngModel
                                   autocomplete="off"
                                   maxlength="1"
                                   min="0"
                                   max="2"
                                   disabled
                                   (keydown.space)="$event.preventDefault();"
                                   (keydown)="onKeyDown($event)"
                                   (paste)="onPaste($event)">
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12"
                         *ngIf="isSubscription && ticket._type === familySubscription">
                        <mat-form-field appearance="outline"
                                        class="asp-full-width"
                                        autofocus
                                        floatLabel="always">
                            <mat-label class="float-left">
                                {{'TICKET_DETAILS.CHILD'| translate}}
                            </mat-label>
                            <input matInput
                                   type="number"
                                   name="children"
                                   [(ngModel)]="ticket._children"
                                   #children="ngModel"
                                   id="children"
                                   ngModel
                                   autocomplete="off"
                                   maxlength="1"
                                   [min]="0"
                                   [max]="4"
                                   (keydown.space)="$event.preventDefault();"
                                   (keydown)="onKeyDown($event)"
                                   (paste)="onPaste($event)"
                                   appAutofocus>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 d-flex asp-margin-bottom">
                        <mat-error
                            class="asp-error-m0"
                            *ngIf="maxAllowedPersons()">
                            {{'TICKET_DETAILS.MAX_ERROR'| translate}}
                        </mat-error>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12" *ngIf="isSubscription">
                        <mat-form-field appearance="outline"
                                        class="asp-full-width">
                            <mat-label class="float-left">{{'PERSONAL_DETAILS.FIRST_NAME'| translate}}</mat-label>
                            <input matInput
                                   type="text"
                                   id="firstName"
                                   [(ngModel)]="ticket._person._firstName"
                                   ngModel
                                   name="firstName"
                                   #firstName="ngModel"
                                   required
                                   autocomplete="off"
                                   class="capitalize"
                                   [ngClass]="{'has-error': firstName.hasError('required')}">
                            <mat-error
                                class="asp-error"
                                *ngIf="firstName.touched && firstName.invalid && firstName.hasError('required')">
                                {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.FIRST_NAME'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12" *ngIf="isSubscription">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">{{'PERSONAL_DETAILS.LAST_NAME'| translate}}</mat-label>
                            <input matInput
                                   type="text"
                                   id="lastName"
                                   [(ngModel)]="ticket._person._lastName"
                                   ngModel
                                   name="lastName"
                                   #lastName="ngModel"
                                   required
                                   class="capitalize"
                                   autocomplete="off"
                                   [ngClass]="{'has-error': lastName.hasError('required')}">
                            <mat-error
                                class="asp-error"
                                *ngIf="lastName.touched && lastName.invalid && lastName.hasError('required')">
                                {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.LAST_NAME'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12" *ngIf="isSubscription">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.EMAIL'| translate}}
                            </mat-label>
                            <input matInput
                                   type="text"
                                   id="email"
                                   [(ngModel)]="ticket._person._email"
                                   ngModel
                                   name="email"
                                   #email="ngModel"
                                   required
                                   autocomplete="off"
                                   [ngClass]="{'has-error': email.hasError('required')}"
                                   (keydown.space)="$event.preventDefault();"
                                   pattern="{{emailPattern}}">
                            <mat-error *ngIf="email.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.EMAIL'| translate}}
                            </mat-error>

                            <mat-error *ngIf="email.hasError('pattern')"
                                       class="asp-error"> {{'PERSONAL_DETAILS.PATTERN_EMAIL'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12" *ngIf="isSubscription">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.PHONE'| translate}}
                            </mat-label>
                            <input matInput
                                   type="text"
                                   id="phone"
                                   maxlength="13"
                                   [(ngModel)]="ticket._person._phone"
                                   ngModel
                                   name="phone"
                                   #phone="ngModel"
                                   required
                                   autocomplete="off"
                                   [ngClass]="{'has-error': phone.hasError('required')}"
                                   (keydown.space)="$event.preventDefault();"
                                   (keydown)="onKeyDown($event)"
                                   (paste)="onPaste($event)"
                                   pattern="{{phonePattern}}">
                            <mat-error *ngIf="phone.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.PHONE'| translate}}
                            </mat-error>
                            <mat-error *ngIf="phone.hasError('pattern')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.PATTERN_PHONE'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="isSubscription && ticket._ticket._isStudent === true"
                         class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.STUDENT_CARD'| translate}}
                            </mat-label>
                            <input matInput
                                   type="text"
                                   id="studentCardNr"
                                   maxlength="13"
                                   [(ngModel)]="ticket._person._studentCardNr"
                                   ngModel
                                   name="studentCardNr"
                                   #studentCardNr="ngModel"
                                   required
                                   autocomplete="off"
                                   [ngClass]="{'has-error': studentCardNr.hasError('required')}"
                                   (keydown.space)="$event.preventDefault();"
                                   (paste)="onPaste($event)">
                            <mat-error *ngIf="studentCardNr.hasError('required')"
                                       class="asp-error asp-error-bottom">
                                {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.STUDENT_CARD_SHORT'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12"
                         *ngIf="(isSubscription && ticket._type !== familySubscription)">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.AGE_GROUP'| translate}}
                            </mat-label>
                            <mat-select class="p-full-width p-select-adjusted"
                                        #ageGroup="ngModel"
                                        name="ageGroup"
                                        [(ngModel)]="ticket._person._ageGroup"
                                        ngModel
                                        id="ageGroup"
                                        required
                                        [compareWith]="compareFn"
                                        #select="matSelect">
                                <mat-option *ngFor="let item of ageGroupsList" [value]="item">
                                    {{ item}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="ageGroup.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED_SELECT'| translate}} {{'PERSONAL_DETAILS.AGE_GROUP'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.COUNTRY'| translate}}
                            </mat-label>
                            <mat-select class="p-full-width p-select-adjusted"
                                        [(ngModel)]="ticket._person._country"
                                        ngModel
                                        name="country"
                                        #country="ngModel"
                                        required
                                        #select="matSelect">
                                <mat-option *ngFor="let item of countries" [value]="item">
                                    {{ item }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="country.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED_SELECT'| translate}} {{'PERSONAL_DETAILS.COUNTRY'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12"
                         *ngIf="ticket._person._country === 'Romania'">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.REGION'| translate}}
                            </mat-label>
                            <mat-select class="p-full-width p-select-adjusted"
                                        [(ngModel)]="ticket._person._region"
                                        ngModel
                                        name="region"
                                        #region="ngModel"
                                        required
                                        #select="matSelect">
                                <mat-option *ngFor="let item of regions" [value]="item">
                                    {{ item }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="region.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED_SELECT'| translate}} {{'PERSONAL_DETAILS.REGION'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-12"
                         *ngIf="ticket._person._country === 'Romania' && isSubscription">

                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.ADDRESS'| translate}}
                            </mat-label>
                            <textarea matInput
                                      type="textarea"
                                      id="address"
                                      rows="3"
                                      [(ngModel)]="ticket._person._address"
                                      ngModel
                                      name="address"
                                      #address="ngModel"
                                      required
                                      autocomplete="off"
                                      [ngClass]="{'has-error': address.hasError('required')}"></textarea>
                            <mat-error *ngIf="address.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.ADDRESS'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-12"
                         *ngIf="isTicket">
                        <mat-form-field appearance="outline" class="asp-full-width">
                            <mat-label class="float-left">
                                {{'PERSONAL_DETAILS.AGE_GROUP'| translate}}
                            </mat-label>
                            <mat-select class="p-full-width p-select-adjusted"
                                        #ageGroup="ngModel"
                                        name="ageGroup"
                                        [(ngModel)]="ticket._person._ageGroup"
                                        ngModel
                                        id="ageGroup"
                                        required
                                        [compareWith]="compareFn"
                                        #select="matSelect">
                                <mat-option *ngFor="let item of ageGroupsList" [value]="item">
                                    {{ item}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="ageGroup.hasError('required')"
                                       class="asp-error">
                                {{'PERSONAL_DETAILS.REQUIRED_SELECT'| translate}} {{'PERSONAL_DETAILS.AGE_GROUP'| translate}}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-6 text-left astra-availability-p"
                         *ngIf="isTicket">
                        <span class="astra-availability">
                            {{'PERSONAL_DETAILS.TICKET_AVAILABILITY_NEW' | translate}}
                            <b>{{countTicketAvailability()}}</b>.
                            {{'PERSONAL_DETAILS.ENTRANCES' | translate}}
                            <b>{{ticket._ticket._entrances}}</b>.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

