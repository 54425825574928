import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private formEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public formEvent$ = this.formEvent.asObservable();

    public setFormEvent(event: boolean): void {
        this.formEvent.next(event);
    }

}
