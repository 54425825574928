import {Component, OnInit} from '@angular/core';
import {Ticket} from 'src/app/shared/models/ticket';
import {DataService} from 'src/app/shared/services/data.service';

@Component({
    selector: 'app-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
    public year: any;
    public tickets: Ticket[];
    public subscriptions: Ticket[];

    constructor(private service: DataService) {
    }

    public ngOnInit(): void {
        this.year = new Date().getFullYear();
        this.service.getTicketTypes('false').subscribe((response) => this.tickets = response)
        this.service.getTicketTypes('true').subscribe((response) => this.subscriptions = response)
    }

    public onNavigate() {
        window.open('https://www.muzeulastra.ro', '_blank');
    }
}
