<div class="content">
    <div class="row astra-margin-15"
         fxShow
         fxHide.xs="true">
        <div class="col-md-12 col-lg-12 ">
            <h2>{{'APP.HEADER'| translate}}</h2>
        </div>
        <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12">
            <h4>{{'APP.DETAILS' | translate}}</h4>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 astra-card-padding">
            <div class="panel panel-widget panel-size pointer-cursor"
                 fxFlex="row"
                 fxFlexAlign="start center"
                 (click)="goTo('/abonamente')">
                <div class="row no-padding">
                    <div class="col-xs-3 col-sm-4 col-lg-5 widget-left ab-background"
                         fxShow
                         fxHide.xs="false"
                         fxHide.ls-sm="true"></div>
                    <div class="col-xs-9 col-sm-8 col-lg-7 widget-right">
                        <div class="row">
                            <div class="col-sm-12 large title-size">
                                {{'CARDS.SUBSCRIPTION'| translate}}
                            </div>
                            <div class="col-sm-12 small">
                                {{'CARDS.S_DESCRIPTION' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 astra-card-padding">
            <div class="panel panel-widget panel-size pointer-cursor"
                 fxFlex="row"
                 fxFlexAlign="start center"
                 (click)="goTo('/bilete')">
                <div class="row no-padding">
                    <div class="col-xs-3 col-sm-4 col-lg-5 widget-left t-background"
                         fxShow
                         fxHide.xs="false"
                         fxHide.ls-sm="true"></div>
                    <div class="col-xs-9 col-sm-8 col-lg-7 widget-right">
                        <div class="row">
                            <div class="col-sm-12 large title-size">
                                {{'CARDS.TICKETS' | translate}}
                            </div>
                            <div class="col-sm-12 small">
                                {{'CARDS.T_DESCRIPTION'| translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

