<form #userForm="ngForm">
    <div class="wraper wraper-width" fxLayout="column">
        <div class="headerWraper" fxLayout="column">
            <div class="row">
                <div class="col-xs-12 col-sm-12">
                    <div class="container">
                        <div class="bottom-adjust">
                            <div class="table-adjust" id="tickets-tables">
                                <table class="table col-sm-12 table-condensed cf"
                                       [ngClass]="isTicket? 'asp-table-block': ' asp-table'">
                                    <thead class="cf">
                                    <tr>
                                        <th scope="col" class="text-center">
                                            <span *ngIf="isTicket">{{'TICKET_DETAILS.TICKET_TYPE'| translate}}</span>
                                            <span
                                                *ngIf="isSubscription">{{'TICKET_DETAILS.SUBSCRIPTION_TYPE'| translate}}</span>
                                        </th>
                                        <th scope="col" class="text-center">
                                            {{'TICKET_DETAILS.TICKET_PRICE'| translate}}
                                        </th>
                                        <th scope="col" class="text-center">
                                            {{'TICKET_DETAILS.AMOUNT'| translate}}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let ticket of ticketType; let i = index; trackBy: customTrackBy;">
                                        <td scope="row" class="d-flex text-left align-self-center"
                                            #type>
                                            <span class="asp-title-descr"> {{ticket._description._current}}</span>
                                        </td>
                                        <td class="d-flex text-center align-self-center"
                                            data-title="{{'TICKET_DETAILS.TICKET_PRICE'| translate}}"
                                            #price
                                            [ngClass]="ticket._quantity !== 0 ? 'increase-margin': 'asp-min-height'">
                                            {{ticket._price}} {{ticket._currency}}
                                        </td>
                                        <td>
                                            <div class="input-group d-flex asp-width">
                                                <span class="input-group-btn">
                                                    <button type="button"
                                                            class="btn  btn-warning btn-number"
                                                            (click)="decrement(ticket, i)"
                                                            [disabled]="ticket._quantity === 0">
                                                        <span class="material-icons">remove</span>
                                                    </button>
                                                </span>
                                                <span class="input-group-btn text-center">
                                                    <input type="text"
                                                           min="0"
                                                           max="100"
                                                           maxlength="2"
                                                           id="quantity"
                                                           [(ngModel)]="ticket._quantity"
                                                           name="quantity"
                                                           ngModel
                                                           #quantity="ngModel"
                                                           autocomplete="off"
                                                           pattern="[0-9.]+"
                                                           class="form-control input-number asp-input"
                                                           (keypress)="onKeyPress($event)"
                                                           (paste)="onPaste($event)"
                                                           (keyup)='updateQuantityOnChange($event, ticket, i)'
                                                           onfocus="this.value === 0 ? this.value = '': this.value"/>
                                                </span>

                                                <span class="input-group-btn">
                                                    <button type="button"
                                                            class="btn btn-warning btn-number"
                                                            (click)="increment(ticket, i)">
                                                        <span class="material-icons">add</span>
                                                    </button>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
