<div class="row" style="margin: 0px">
    <div class="col-12">
        <div class="astro-header" fxShow fxHide.xs="true">
            <div class="row align-items-end header-margins">
                <div class="col-md-12 col-lg-12">
                    <div class="row">
                        <div class="col-xs-12 col-md-12 col-sm-12 col-lg-12">
                            <h4 *ngIf="isSubscription">{{'APP.SUBSCRIPTION_HEADER' | translate}}</h4>
                            <h4 *ngIf="isTicket">{{'APP.TICKET_HEADER' | translate}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <mat-horizontal-stepper [linear]="isLinear"
                                #stepper
                                class="ap-full-width d-flex justify-content-center d-block d-inline-block astra-stepper"
                                (selectionChange)="stepChanged($event, stepper);">

            <!--SELECTARE BILET-->
            <mat-step [stepControl]="ticketDetailsForm"
                      #ticketDetails>
                <form #ticketDetailsForm="ngForm" class="d-flex flex-column justify-content-between">
                    <ng-template matStepLabel>
                        <span *ngIf="isTicket"> {{'WIZARD_STEPS.TICKET_DETAILS' | translate}}</span>
                        <span *ngIf="isSubscription"> {{'WIZARD_STEPS.SUBSCRIPTION_DETAILS' | translate}}</span>
                    </ng-template>

                    <app-ticket-details [form]="ticketDetailsForm"
                                        [isSubscription]="isSubscription"
                                        [isTicket]="isTicket"
                                        (notify)="onNotifyOfSelectedTickets($event)"></app-ticket-details>

                    <div class="actions text-center d-flex justify-content-center flex-wrap">
                        <button mat-raised-button
                                matStepperNext
                                (click)="getSelectedTickets()">
                            <span>{{'COMMON.NEXT' | translate}}</span>
                            <span class="material-icons">navigate_next</span>
                        </button>
                    </div>
                </form>
            </mat-step>

            <!--DATE PENTRU BILET-->
            <mat-step #ticketDataForm
                      [stepControl]="ticketDataForm"
                      [optional]="false">
                <form #ticketDataForm="ngForm" class="d-flex flex-column justify-content-between">
                    <ng-template matStepLabel>
                        <span *ngIf="isTicket"> {{'WIZARD_STEPS.TICKET_DATA' | translate}}</span>
                        <span *ngIf="isSubscription"> {{'WIZARD_STEPS.SUBSCRIPTION_DATA' | translate}}</span>
                    </ng-template>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <app-ticket-data #appTicketDetails
                                             [form]="ticketDataForm"
                                             [selected]="sTickets"
                                             [isSubscription]="isSubscription"
                                             [isTicket]="isTicket"></app-ticket-data>
                        </div>
                    </div>
                </form>
                <div class="d-flex justify-content-between actions align-content-end flex-wrap asp-z-index">
                    <button mat-raised-button
                            matStepperPrevious
                            class="float-left">
                        <mat-icon>navigate_before</mat-icon>
                        {{'COMMON.BACK' | translate}}
                    </button>
                    <button mat-raised-button
                            class="float-right"
                            matStepperNext
                            [disabled]="disableNextButton(sTickets) ||(sTickets &&sTickets.length === 0)">
                        {{'COMMON.NEXT' | translate}}

                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </mat-step>

            <!--DETALII DE PLATA-->
            <mat-step #paymentDetails
                      [stepControl]="paymentDetails">
                <form #paymentData="ngForm" class="d-flex flex-column justify-content-between">
                    <ng-template matStepLabel>
                        <span> {{'WIZARD_STEPS.CONFIRM_ORDER' | translate}}</span>
                    </ng-template>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12">
                            <app-payment-details [form]="paymentDetails"
                                                 [selected]="sTickets"
                                                 [data]="payData"
                                                 [isSubscription]="isSubscription"
                                                 [isTicket]="isTicket"
                                                 (notify)="onNotifyPersonalData($event)"></app-payment-details>
                        </div>
                    </div>
                </form>

                <div class="d-flex justify-content-between actions align-content-end flex-wrap">
                    <button mat-raised-button
                            matStepperPrevious
                            class="float-left">
                        <mat-icon>navigate_before</mat-icon>
                        {{'COMMON.BACK' | translate}}
                    </button>
                    <button mat-raised-button
                            class="float-right"
                            (click)="goToPayment()"
                            [disabled]="!payData._person._email || !payData._person._phone || !payData._person._firstName || !payData._person._lastName || payData._person._gdpr === false   ">
                        {{'COMMON.PAY' | translate}}
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>
