<div class="astra-content">
    <app-header></app-header>

    <div class="main-content astra-content-padding">
        <div *ngIf="showBanner === false"
             class="banner text-center">
            <span class="material-icons banner-icon">warning</span>
            <span fxShow> {{'APP.WARNING.WORKING'| translate}}</span>
            <span fxShow
                  fxHide.xs="true">{{'APP.WARNING.COMING_BACK'| translate}}</span>
        </div>
        <div class="content-margins">
            <router-outlet #outlet></router-outlet>
        </div>
    </div>

    <div class="footer">
        <app-footer></app-footer>
    </div>
</div>
