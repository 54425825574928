<div *ngIf="!selected || selected.length === 0">
    <div class="wraper wraper-width">
        <div class="row asp-border-b h-overflow">
            <div class="col-xs-12 col-sm-12 d-flex text-center asp-margin-10">
                <span> {{'PAYMENT_DETAILS.NOT_SELECTED' | translate}}</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="selected && selected.length !== 0">
    <form #userForm="ngForm">
        <div class="wraper wraper-width">
            <div class="row selected-border">
                <div class="col-xs-12 col-sm-12 d-flex text-left asp-margin-10" fxShow fxHide.xs="true">
                    <span> {{'TICKET_DETAILS.HINT' | translate}}</span>
                </div>
            </div>

            <div class="row d-flex asp-mb-10" *ngFor="let s of selected">
                <div class="col-xs-12 col-md-12 col-sm-12">
                    <div class="card card-stats asp-card-margins">
                        <div class="card-header card-header-danger card-header-icon">
                            <div class="asp-card-title text-left asp-card-title-margin">
                                <span class="asp-descr">
                                    {{s._ticket._description._current}} - {{s._ticket._price}} {{s._ticket._currency}}
                                </span>
                            </div>
                            <div class="card-category asp-margin-25">
                                <div class="col-xs-12 col-sm-12 col-md-12">
                                    <app-personal-details #personalDetailsForm
                                                          [ticket]="s"
                                                          [isSubscription]="isSubscription"
                                                          [isTicket]="isTicket"
                                                          (notify)="onNotifyWithPersonalDetails($event)">

                                    </app-personal-details>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
