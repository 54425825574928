import {Component, OnInit} from '@angular/core';
import {StorageService} from 'src/app/shared/services/storage.service';
import {TranslateService} from '@ngx-translate/core';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {Router} from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    public isHeaderSticky: boolean;
    public languages: string[];
    public lngEN: string;
    public lngRO: string;
    public selectedLanguage: string;

    constructor(private storageService: StorageService,
                private translate: TranslateService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.isHeaderSticky = true;
        this.lngEN = SharedConstants.EN;
        this.lngRO = SharedConstants.RO;
        this.languages = SharedConstants.LANGUAGES;
        this.selectedLanguage = this.storageService.get(SharedConstants.LOCALE) || SharedConstants.RO;
    }

    public goToHomePage(): void {
        this.router.navigate(['/']);
    }

    public switchLanguage(language: string): void {
        this.storageService.set(SharedConstants.LOCALE, language.toLowerCase());
        this.translate.use(language.toLowerCase());
        this.selectedLanguage = language;
    }
}
