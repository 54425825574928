<div class="row asp-container">
    <div class="col-xs-12 col-sm-12 col-md-4">
        <div class="asp-left-container">
            <span class="asp-title-left">{{'WIZARD_STEPS.ORDER_DETAILS' | translate}} </span>
            <div class="asp-table">
                <table class="table">
                    <thead class="cf">
                    <tr>
                        <th scope="col" class="text-left">{{'PAYMENT_DETAILS.HINT'| translate}}</th>
                        <th scope="col" class="text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let ticket of groupByType(selected)">
                        <th scope="row" class="d-flex text-left align-self-center">
                            {{ticket['description']?._current}}
                        </th>
                        <td class="d-flex text-center align-self-center">
                            {{ticket['count']}} {{'PAYMENT_DETAILS.PCS' | translate}}
                            x  {{ticket['price']}} {{ticket['currency']}}
                        </td>
                    </tr>
                    <tr class="asp-border">
                        <th scope="row" class="d-flex text-left align-self-center">
                            {{'PAYMENT_DETAILS.TOTAL' | translate}}
                        </th>
                        <td class="d-flex text-center align-self-center">
                            {{count(selected)}} {{'PAYMENT_DETAILS.CURRENCY' | translate}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-8">
        <div class="asp-right-container">
            <span class="asp-title-right">{{'WIZARD_STEPS.PERSONAL_DETAILS' | translate}}</span>
            <form #userForm="ngForm"
                  (keyup.enter)="keyUpSubmit(userForm)"
                  autocomplete="off"
                  class="asp-form asp-form-margin">
                <div fxFlex="column" fxFlexAlign="start center" class="asp-form asp-bg-white">
                    <div class="row asp-form" fxFlex="row" fxFlexAlign="start center">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline"
                                                    class="asp-full-width">
                                        <mat-label class="float-left">{{'PERSONAL_DETAILS.FIRST_NAME'| translate}}
                                        </mat-label>
                                        <input matInput
                                               type="text"
                                               id="firstName"
                                               [(ngModel)]="data._person._firstName"
                                               ngModel3
                                               name="firstName"
                                               #firstName="ngModel"
                                               required
                                               autocomplete="off"
                                               class="capitalize"
                                               [ngClass]="{'has-error': firstName.hasError('required')}">
                                        <mat-error
                                            class="asp-error"
                                            *ngIf="firstName.touched && firstName.invalid && firstName.hasError('required')">
                                            {{'PERSONAL_DETAILS.REQUIRED'| translate}}
                                            {{'PERSONAL_DETAILS.FIRST_NAME'|translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline" class="asp-full-width">
                                        <mat-label class="float-left">{{'PERSONAL_DETAILS.LAST_NAME'| translate}}
                                        </mat-label>
                                        <input matInput
                                               type="text"
                                               id="lastName"
                                               [(ngModel)]="data._person._lastName"
                                               ngModel
                                               name="lastName"
                                               #lastName="ngModel"
                                               required
                                               class="capitalize"
                                               autocomplete="off"
                                               [ngClass]="{'has-error': lastName.hasError('required')}">
                                        <mat-error
                                            class="asp-error"
                                            *ngIf="lastName.touched && lastName.invalid && lastName.hasError('required')">
                                            {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.LAST_NAME'| translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline" class="asp-full-width">
                                        <mat-label class="float-left">
                                            {{'PERSONAL_DETAILS.EMAIL'| translate}}
                                        </mat-label>
                                        <input matInput
                                               type="text"
                                               id="email"
                                               [(ngModel)]="data._person._email"
                                               ngModel
                                               name="email"
                                               #email="ngModel"
                                               required
                                               autocomplete="off"
                                               [ngClass]="{'has-error': email.hasError('required')}"
                                               (keydown.space)="$event.preventDefault();"
                                               pattern="{{emailPattern}}">
                                        <mat-error *ngIf="email.hasError('required')"
                                                   class="asp-error">
                                            {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.EMAIL'| translate}}
                                        </mat-error>

                                        <mat-error *ngIf="email.hasError('pattern')"
                                                   class="asp-error"> {{'PERSONAL_DETAILS.PATTERN_EMAIL'| translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <mat-form-field appearance="outline" class="asp-full-width">
                                        <mat-label class="float-left">
                                            {{'PERSONAL_DETAILS.PHONE'| translate}}
                                        </mat-label>
                                        <input matInput
                                               type="text"
                                               id="phone"
                                               maxlength="13"
                                               [(ngModel)]="data._person._phone"
                                               ngModel
                                               name="phone"
                                               #phone="ngModel"
                                               required
                                               autocomplete="off"
                                               [ngClass]="{'has-error': phone.hasError('required')}"
                                               (keydown.space)="$event.preventDefault();"
                                               (keydown)="onKeyDown($event)"
                                               (paste)="onPaste($event)"
                                               pattern="{{phonePattern}}">
                                        <mat-error *ngIf="phone.hasError('required')"
                                                   class="asp-error">
                                            {{'PERSONAL_DETAILS.REQUIRED'| translate}} {{'PERSONAL_DETAILS.PHONE'| translate}}
                                        </mat-error>
                                        <mat-error *ngIf="phone.hasError('pattern')"
                                                   class="asp-error">
                                            {{'PERSONAL_DETAILS.PATTERN_PHONE'| translate}}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left astra-gdpr">
                                    <mat-checkbox (change)="data._person._gdpr = !data._person._gdpr"
                                                  [checked]="data._person._gdpr === true"></mat-checkbox>
                                    <span class="text-justify">
                                        {{'APP.GDPR' | translate}}
                                    </span>
                                </div>
                                <br/>
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left astra-gdpr">
                                    {{'APP.GDPR_TERMS' | translate}}
                                    <a target="_blank"
                                       routerLink="/terms-and-conditions">{{'APP.GDPR_HERE' | translate}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


