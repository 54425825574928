import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LayoutComponent} from 'src/app/components/layout/layout.component';
import {SuccessMessageComponent} from 'src/app/components/success-message/success-message.component';
import {HomeComponent} from 'src/app/components/home/home.component';
import {ErrorMessageComponent} from 'src/app/components/error-message/error-message.component';
import {TermsComponent} from 'src/app/components/terms/terms.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'acasa',
        component: HomeComponent
    },
    {
        path: 'not-found',
        component: ErrorMessageComponent
    },
    {
        path: 'success',
        component: SuccessMessageComponent
    },
    {
        path: 'terms-and-conditions',
        component: TermsComponent
    },
    {
        path: ':id',
        component: LayoutComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
