import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {MaterialModule} from 'src/app/shared/material.module';
import {LayoutComponent} from './components/layout/layout.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TicketDetailsComponent} from './components/ticket-details/ticket-details.component';
import {PersonalDetailsComponent} from './components/personal-details/personal-details.component';
import {SuccessMessageComponent} from './components/success-message/success-message.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {WebpackTranslateLoader} from 'src/app/webpack-translate-loader';
import {PaymentDetailsComponent} from './components/payment-details/payment-details.component';
import {HomeComponent} from './components/home/home.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {DataService} from 'src/app/shared/services/data.service';
import {environment} from 'src/environments/environment';
import {APIService} from 'src/app/shared/services/api.service';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { TicketDataComponent } from './components/ticket-data/ticket-data.component';
import {AutoFocusDirective} from 'src/app/shared/directives/autofocus.directive';
import { TermsComponent } from './components/terms/terms.component';

export function getBaseUrl(): string {
    // return document.getElementsByTagName('base')[0].href;

    return environment.baseUrl;
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        LayoutComponent,
        TicketDetailsComponent,
        PersonalDetailsComponent,
        SuccessMessageComponent,
        PaymentDetailsComponent,
        HomeComponent,
        ErrorMessageComponent,
        TicketDataComponent,
        AutoFocusDirective,
        TermsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MaterialModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
        }),
    ],
    providers: [
        {provide: 'BASE_URL', useFactory: getBaseUrl},
        {provide: MAT_DATE_LOCALE, useValue: 'ro-RO'},
        APIService,
        DataService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
