// tslint:disable:no-any

import {Component, EventEmitter, Input, OnInit, Output, OnChanges} from '@angular/core';
import {User} from 'src/app/shared/models/user';
import {FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/internal/operators';
import {Subject} from 'rxjs';
import {EventService} from 'src/app/shared/services/event.service';
import {SelectedTicket} from 'src/app/shared/models/selected-ticket';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {DataService} from 'src/app/shared/services/data.service';
import * as moment from 'moment';

@Component({
    selector: 'app-personal-details',
    templateUrl: './personal-details.component.html',
    styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent implements OnInit, OnChanges {
    public user: User;
    public emailPattern: string;
    public phonePattern: string;
    public ageGroupsList: string[];
    public countries: string[];
    public regions: string[];
    private unsubscribe$ = new Subject();
    public today: Date;
    public familySubscription: number;

    @Input() public form: FormGroup;
    @Input() public keyupSubmit: boolean;
    @Input() public isTicket: boolean;
    @Input() public ticket: SelectedTicket;
    @Input() public isSubscription: boolean;
    @Input() public isStudent: boolean;
    @Output() public notify = new EventEmitter<any>();


    constructor(private eventService: EventService,
                private service: DataService) {
        this.eventService.formEvent$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((event) => {
                if (event) {
                    this.onSubmit(this.form.value);
                }
            });
    }

    public ngOnInit(): void {
        this.user = new User();
        this.ticket._person = new User();
        this.ticket._person._country = SharedConstants.DEFAULT_COUNTRY;
        this.ticket._validFrom = new Date();
        this.familySubscription = SharedConstants.FAMILY_SUBSCRIPTION_ID;
        this.initConstants();
    }

    public ngOnChanges(): void {
    }

    public keyUpSubmit(form): void {
        if (this.keyupSubmit) {
            this.onSubmit(form);
        }
    }

    public onSubmit(form): void {

        if (!this.form.valid) {
            this.notify.emit(null);
        }

        this.notify.emit(form);
    }

    public onPaste(event): void {
        event.preventDefault();
    }

    public onKeyDown(event) {
        return (event.ctrlKey || event.altKey
            || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
            || (95 < event.keyCode && event.keyCode < 106)
            || (event.keyCode === 8) || (event.keyCode === 9)
            || (event.keyCode > 34 && event.keyCode < 40)
            || (event.keyCode === 46)
            || (event.keyCode === 187 && event.shiftKey === true));
    }

    public compareFn(s1: string, s2: string): boolean {
        return (s1 ? s1 : '') === (s2 ? s2 : '');
    }

    public maxAllowedPersons(): boolean {
        return this.ticket._adults + this.ticket._children > 5;
    }

    public countTicketAvailability(): string {

        return moment(new Date()).add(2, 'M').format('DD-MM-YYYY');
    }

    private initConstants(): void {
        this.today = new Date();
        this.emailPattern = SharedConstants.EMAIL_PATTERN;
        this.phonePattern = SharedConstants.PHONE_PATTERN;

        this.getAgeGroups();
        this.getCountries();
        this.getRegions();

    }

    private getAgeGroups(): void {
        this.ageGroupsList = [];
        this.service.getAgeGroups().subscribe((response) => {
            this.ageGroupsList = response;

            if (this.ticket._type === SharedConstants.STUDENT_SUBSCRIPTION || this.ticket._type === SharedConstants.STUDENT_TICKET) {
                this.ageGroupsList = this.ageGroupsList.slice(0, 3);
            }
            if (this.ticket._type === SharedConstants.ADULT_SUBSCRIPTION || this.ticket._type === SharedConstants.ADULT_TICKET) {
                this.ageGroupsList = this.ageGroupsList.slice(1, 9);
            }
            if (this.ticket._type === SharedConstants.SENIOR_TICKET) {
                this.ageGroupsList = this.ageGroupsList.slice(5, 9);
            }

        });
    }

    private getCountries(): void {
        this.countries = [];
        this.service.getCountries().subscribe((response) => {
            this.countries = response;

        });
    }

    private getRegions(): void {
        this.regions = [];
        this.service.getRegions().subscribe((response) => {
            this.regions = response;

        });
    }
}
