import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {User} from 'src/app/shared/models/user';
import {Router} from '@angular/router';
import {NotificationsService} from 'src/app/shared/services/notifications.service';
import {DataService} from 'src/app/shared/services/data.service';
import {SelectedTicket} from 'src/app/shared/models/selected-ticket';
import {PaymentDetails} from 'src/app/shared/models/payment-details';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import * as moment from 'moment';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
    public isLinear = true;
    public sTickets: SelectedTicket[];
    public user: User;
    public isSubscription: boolean;
    public isTicket: boolean;
    public payData: PaymentDetails;

    @ViewChild('stepper', {static: true}) public stepper;
    @ViewChild('ticketDetails', {static: true}) public ticketDetails;
    @ViewChild('personalDetails', {static: true}) public personalDetails;
    @ViewChild('paymentData', {static: true}) public paymentData;

    constructor(private router: Router,
                private notificationsService: NotificationsService,
                private ref: ChangeDetectorRef,
                private service: DataService) {
    }

    public ngOnInit(): void {
        this.payData = new PaymentDetails();
        this.payData._person = new User();
        this.isSubscription = false;
        this.isTicket = false;
        if (this.router.url.toString().includes('abonamente')) {
            this.isSubscription = true;
        } else {
            this.isTicket = true;
        }
    }

    public ngAfterViewInit(): void {
        this.stepper.selectedIndex = 0;
        this.ref.detectChanges();
        this.ref.markForCheck();
    }

    public disableNextButton(tickets): boolean {
        if (this.stepper.selectedIndex === 1 && this.sTickets) {
            const ageGroup = this.sTickets.find((s) => s._person._ageGroup === '');
            const region = this.sTickets.find(
                (s) => s._person._country === SharedConstants.DEFAULT_COUNTRY && s._person._region === '');
            const firstName = this.sTickets.find((s) => s._person._firstName === '');
            const lastName = this.sTickets.find((s) => s._person._lastName === '');
            const email = this.sTickets.find((s) => s._person._email === '');
            const phone = this.sTickets.find((s) => s._person._email === '');
            const address = this.sTickets.find(
                (s) => s._person._country === SharedConstants.DEFAULT_COUNTRY && s._person._address === '');
            if (tickets && this.isTicket && (ageGroup || region)) {
                return true;
            } else if (tickets && this.isSubscription && (region || firstName || lastName || email || phone || address)) {
                return true;
            } else {
                return false;
            }
        }
    }

    public onNotifyOfSelectedTickets(tickets: SelectedTicket[]): void {
        this.sTickets = tickets;
        tickets.length !== 0 ? this.ticketDetails.completed = true : this.ticketDetails.completed = false;
    }

    public onNotifyPersonalData(data): void {
        this.payData = data;
    }

    public getSelectedTickets(): void {
        const selected = [];
        if (this.sTickets) {
            this.sTickets.forEach((s: SelectedTicket) => {
                selected.push(new SelectedTicket(s));

            });
        }
        this.sTickets = selected;
    }

    public stepChanged(event, stepper): void {
        stepper.selected.interacted = false;
    }

    public goToPayment(): void {
        if (this.sTickets && this.sTickets.length === 0) {
            if (this.isTicket) {
                this.notificationsService.showError('ERRORS.SELECT_TICKETS');
            }
            if (this.isSubscription) {
                this.notificationsService.showError('ERRORS.SELECT_SUBSCRIPTION');
            }
        }
        if (this.sTickets && this.sTickets.length !== 0) {

            this.executePayment(this.user, this.sTickets);
        }
    }


    private executePayment(personalDetails: User, tickets: SelectedTicket[]): void {
        this.payData._tickets = [];
        tickets.forEach((ticket: SelectedTicket) => {

            const months = ticket._ticket._description._ro.includes('BILET') ? 2 : 12;
            const toDate = moment(ticket._validFrom).add(months, 'M').toDate();

            ticket._validTo = new Date(toDate);

            this.payData._tickets.push(new SelectedTicket(ticket));
        });

        this.service.executePayment(this.payData).subscribe(() => {
            return;
        });
    }
}
