<div class="row d-flex justify-content-between">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-danger card-header-icon">
                <div class="card-category">

                    <h3 class="asp-card-title">Termeni și Condiții</h3>
                    <br/>

                    <p class="text-primary astra-font-bold">Angajamentul dumneavoastră</p>
                    <p>În urma accesării de către dumneavoastră a plății on line, acceptați și termenii și condițiile
                        care stau la baza oricărui contract între Muzeul ASTRA și dumneavoastră</p>
                    <br/>

                    <p class="astra-font-bold">Bunuri și servicii comercializate cu card online</p>
                    <p class="astra-font-bold">Bilete individuale</p>
                    <p *ngFor="let ticket of tickets">
                        <span class="astra-text-sentence"> {{ticket._description._current}} </span> -
                        preț {{ticket._price}} lei
                    </p>

                    <p class="astra-font-bold"> Abonamente anuale</p>
                    <p *ngFor="let subscription of subscriptions" class="text-capitalize">
                        {{subscription._description._current}} - preț {{subscription._price}} lei
                    </p>
                    <br/>

                    <p class="astra-font-bold">Prețurile includ toate taxele aplicabile și nu există nicio taxă
                        suplimentară. După ce ați plătit, prețul biletelor și abonamentelor rămâne neschimbat.
                    </p>
                    <p class="astra-font-bold">Prețurile afișate pe site pot fi modificate fără notificare
                        prealabilă.</p>
                    <br/>

                    <p class="astra-font-bold">Anulare</p>
                    <p>Din păcate, odată achiziționate, taxa pentru achiziționarea biletelelor și abonamentelor online
                        nu se returnează. </p>
                    <p>Aveți posibilitatea de a utiliza biletul cumpărat online două luni de la data achiziționării.
                        Biletul este valabil pentru o singură intrare.</p>
                    <p>Abonamentele cumpărate online sunt valabile un an de zile de la data achiziționării.</p>
                    <br/>

                    <p class="astra-font-bold">Rezervare on line</p>
                    <p>Biletele online achiziționate nu pot fi utilizate împreună cu nicio altă ofertă. Nu se poate
                        revinde biletul.</p>
                    <p>Orice reduceri sau oferte online sunt disponibile doar pentru cantități și perioade limitate și
                        nu vor fi disponibile pentru rezervările efectuate telefonic sau în persoană.</p>
                    <p>Vă rugăm să aduceți numărul de referință al rezervării și / sau imprimarea prin e-mail.</p>
                    <p>Prețurile sunt corecte la momentul apariției și pot fi modificate fără notificare.</p>
                    <br/>
                    <br/>


                    <h3 class="asp-card-title">Protecția datelor si politici de confidențialitate</h3>
                    <br/>
                    <p class="astra-font-bold">Aceste politici de confidențialitate explică de ce Muzeul ASTRA
                        colectează date cu caracter personal, la ce sunt folosite aceste date, cum sunt păstrate și
                        protejate. Muzeul poate colecta, utiliza și păstra datele cu caracter personal Conform
                        Regulamentului General de Protecția datelor publicat pe site. Muzeul are dreptul de a modifica
                        aceste politici atunci când consideră necesar, publicându-le pe site.</p>

                    <p class="astra-font-bold">Vă rugăm să consultați site-ul muzeului <a (click)="onNavigate()">www.muzeulastra.ro</a>
                        pentru a vă informa cu modificările apărute, care nu pot fi însă aplicate retrospectiv.</p>
                    <p><span class="astra-font-bold">Muzeul ASTRA va respecta întotdeauna Regulamentul General de Protecția Datelor când colectează, stochează și utilizează datele cu caracter personal </span>
                        conform Regulamentul nr. 679/2016 privind protecţia persoanelor fizice în ceea ce priveşte
                        prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date şi de
                        abrogare a Directivei 95/46/CE (Regulamentul general privind protecţia datelor, denumit în
                        continuare şi RGPD)</p>
                    <ul>
                        <p> a) <span class="astra-font-bold">	DEFINIREA NOTIUNILOR </span> cu privire la datele cu
                            caracter personal şi la prelucrarea acestor date:</p>
                        <li><span class="astra-italic-text">„date cu caracter personal” </span>înseamnă orice informaţii
                            privind o persoană fizică identificată sau identificabilă („persoana vizată”); o persoană
                            fizică identificabilă este o persoană care poate fi identificată, direct sau indirect, în
                            special prin referire la un element de identificare, cum ar fi un nume, un număr de
                            identificare, date de localizare, un identificator online, sau la unul sau mai multe
                            elemente specifice, proprii identităţii sale fizice, fiziologice, genetice, psihice,
                            economice, culturale sau sociale;
                        </li>
                        <li><span class="astra-italic-text">„prelucrare” </span>înseamnă orice operaţiune sau set de
                            operaţiuni efectuate asupra datelor cu caracter personal sau asupra seturilor de date cu
                            caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea,
                            înregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea,
                            consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau punerea la dispoziţie
                            în orice alt mod, alinierea sau combinarea, restricţionarea, ştergerea sau distrugerea.
                        </li>
                        <br/>

                    </ul>
                    <br/>
                    <ol>
                        <p> b. <span class="astra-font-bold">DREPTURILE</span> persoanei căreia i se prelucrează datele,
                            conform Regulamentul general privind protecţia datelor nr. 679/ 2016 (RGPD):</p>
                        <li>dreptul de acces: dreptul de a obţine o confirmare că se prelucrează sau nu date cu caracter
                            personal care o privesc şi, în caz afirmativ, acces la datele respective, la informatii cu
                            privire la prelucrarea acestora si la o copie a datelor cu caracter personal procesate
                            (conform art. 15 RGPD).
                        </li>
                        <li>dreptul la rectificarea datelor: dreptul de a obtine rectificarea datelor cu caracter
                            personal inexacte sau completarea datelor cu caracter personal care sunt incomplete (conform
                            art. 16 RGPD).
                        </li>
                        <li>dreptul la ştergerea datelor (”dreptul de a fi uitat”): dreptul de a obtine ştergerea
                            datelor cu caracter personal şi, în cazul în care datele cu caracter personal au fost făcute
                            publice, transmiterea informaţiilor referitoare la solicitarea de ştergere către alţi
                            operatori care prelucreaza datele cu caracter personal (conform art. 17 RGPD).
                        </li>
                        <li>dreptul la restricționarea prelucrării: dreptul de a obţine din partea operatorului
                            restricţionarea prelucrării datelor cu caracter personal (conform art. 18 din RGPD).
                        </li>
                        <li>
                            dreptul la portabilitatea datelor: dreptul de a primi datele cu caracter personal care o
                            privesc şi pe care le-a furnizat operatorului într-un format structurat, utilizat în mod
                            curent şi care poate fi citit automat şi dreptul acesteia de a transmite aceste date altui
                            operator, fără obstacole din partea operatorului căruia i-au fost furnizate datele cu
                            caracter personal, precum si dreptul acesteia pentru ca datele cu caracter personal sa fie
                            transmise direct de la un operator la altul (conform art. 20 RGPD),
                        </li>
                        <li>dreptul de opozitie: dreptul de a se opune prelucrării datelor cu caracter personal cu
                            intenţia de a înceta prelucrarea acestor date (conform art. 21 RGPD),
                        </li>
                        <li>dreptul de retragere în orice moment a consimţământului acordat: dreptul de a retrage
                            oricând un consimţământ dat în vederea opririi unei prelucrări a datelor cu caracter
                            personal care se bazează pe consimţământ. Retragerea nu va afecta legalitatea prelucrării
                            datelor cu caracter personal afectuata pe baza consimţământului acordat înainte de retragere
                            (conform art. 7 RGPD).
                        </li>
                        <li>dreptul de a depune o plângere la o autoritate de supraveghere: dreptul de a depune o
                            plângere la Autoritatea Natională de Supraveghere a Prelucrării Datelor cu Caracter Personal
                            dacă se consideră că prelucrarea datelor este o încălcare a RGPD (art. 77 RGPD).
                        </li>
                    </ol>

                    <br/>
                    <p class="astra-font-bold">Cine este operatorul datelor cu caracter personal?</p>
                    <p>COMPLEXUL NATIONAL MUZEAL ASTRA SIBIU, cu sediul în Sibiu, str. Pădurea Dumbrava nr. 16, telefon
                        0269/202400, CUI 4406304,
                        <a (click)="onNavigate()">www.muzeulastra.ro</a>,
                        email: <a href="mailto: office@muzeulastra.ro">office@muzeulastra.ro</a> este operatorul
                        datelor cu caracter personal pe care ni le transmiteți și răspunde de datele dumneavoastră cu
                        caracter personal în conformitate cu legislația aplicabilă privind protecția datelor.
                    </p>

                    <br/>
                    <p class="astra-font-bold">De ce folosim datele dumneavoastră cu caracter personal?</p>
                    <p>Folosim datele cu caracter personal pentru a vă emite abonamentul de acces în cadrul Complexului
                        Naţional Muzeal ASTRA şi pentru a vă genera facturi (la cerere) pentru serviciile prestate.</p>

                    <br/>
                    <p class="astra-font-bold">Ce tipuri de date cu caracter personal colectăm?</p>
                    <p>Vom prelucra următoarele categorii de date cu caracter personal:</p>
                    <p>- nume, prenume, adresa, serie si nr. CI, nr. matricol (în cazul elevilor/studenţilor), nr. dosar
                        pensie (în cazul pensionarilor), nr. certificatului de handicap/nr. deciziei de handicap (în
                        cazul persoanelor cu disabilităţi şi ale însoţitorului), nr. carnet şomaj, nr. legitimaţie
                        veteran de război sau fost deţinut politic.</p>

                    <br/>
                    <p class="astra-font-bold">Unde vă stocăm datele şi pe ce perioadă?</p>
                    <p>Datele dumneavoastră se stochează într-o bază de date electronică şi se face pe tot parcursul
                        relațiilor contractuale și, după finalizarea acestora, cel puțin pe perioada impusă de
                        prevederile legale aplicabile în domeniu şi pe perioada în care instituţia are obligaţia legală
                        de a păstra corespondenţa cu terții.
                    </p>

                    <br/>
                    <p class="astra-font-bold">Cine are acces la datele dumneavoastră?</p>
                    <p>Categoriile de destinatari de date cu caracter personal sunt: persoana vizată, casierii
                        instituţiei, autorităţi sau instituţii publice locale sau judeţene în cazurile strict
                        determinate cu respectarea legii.</p>

                    <br/>
                    <p class="astra-font-bold"> Care este temeiul juridic pentru prelucrarea datelor cu caracter
                        personal?</p>

                    <p> Colectarea, arhivarea şi prelucrarea datelor persoanelor vizate pentru scopurile menționate mai
                        sus, au ca temei juridic: </p>
                    <ul>
                        <li> consimțământul persoanei atunci când este de acord cu emiterea abonamentului sau cu
                            emiterea facturii
                        </li>
                        <li> obligaţia legală a C.N.M. ASTRA;</li>
                        <li> interesul legitim al C.N.M. ASTRA, pentru activități precum: efectuarea de analize
                            statistice și raportări interne sau către autoritățile, instituțiile sau agențiile de stat
                            sau guvernamentale abilitate.
                        </li>
                    </ul>

                    <br/>
                    <p class="astra-font-bold">Care sunt drepturile dumneavoastră?</p>
                    <p> Vă informăm că vă puteţi exercita toate drepturile prevăzute de
                        <span class="astra-font-bold">Regulamentul nr. 679/ 2016 privind protecţia persoanelor fizice în ceea ce priveşte prelucrarea datelor cu caracter personal şi privind libera circulaţie a acestor date.</span>,
                        precum:
                    </p>
                    <p><span class="astra-font-bold"> Dreptul de acces la date: </span>orice persoană vizată are dreptul
                        de a obţine de la Complexul Naţional Muzeal ASTRA, la cerere (în mod gratuit, pentru o
                        solicitare pe an), confirmarea faptului că datele sale personale sunt sau nu sunt prelucrate de
                        acesta, precum şi următoarele informaţii:</p>
                    <ol>
                        <li>informaţii referitoare la scopurile prelucrării, categoriile de date avute in vedere şi
                            destinatarii sau categoriile de destinatari cărora le sunt dezvăluite datele;
                        </li>
                        <li>comunicarea intr-o formă inteligibilă a datelor care fac obiectul prelucrării, precum şi a
                            oricărei informaţii disponibile cu privire la originea datelor;
                        </li>
                        <li>informaţii asupra principiilor de funcţionare a mecanismului prin care se efectuează orice
                            prelucrare automata a datelor care vizează persoana respectiva;
                        </li>
                        <li>informaţii privind existenta dreptului de intervenţie asupra datelor si a dreptului de
                            opoziţie, precum şi condiţiile in care pot fi exercitate;
                        </li>
                        <li>informaţii asupra posibilităţii de a consulta registrul de evidenta a prelucrărilor de date
                            cu caracter personal, de a înainta plângere către autoritatea de supraveghere, precum si de
                            a se adresa instanţei pentru atacarea deciziilor, in conformitate cu dispoziţiile legale.
                        </li>
                    </ol>

                    <br/>

                    <p>Complexul Naţional Muzeal ASTRA este obligat să comunice informaţiile solicitate, in termen de 30
                        zile de la data primirii cererii.</p>
                    <br/>

                    <p>
                        <span class="astra-font-bold">Dreptul de intervenţie asupra datelor: </span>orice persoană
                        vizată are dreptul de a obţine, în mod gratuit, de la Complexul Naţional Muzeal ASTRA, printr-o
                        cerere întocmită în forma scrisă, datată şi semnată:
                    </p>

                    <ol>
                        <li>rectificarea, actualizarea, blocarea sau ştergerea datelor a căror prelucrare nu este
                            conformă legii, in special a datelor incomplete sau inexacte;
                        </li>
                        <li>transformarea in date anonime a datelor a căror prelucrare nu este conformă cu prevederile
                            RGPD/2016;
                        </li>
                        <li>notificarea către terţii cărora le-au fost dezvăluite datele, dacă aceasta notificare nu se
                            dovedeşte imposibilă sau nu presupune un efort disproporţionat faţă de interesul legitim
                            care ar putea fi lezat.
                        </li>
                    </ol>
                    <br/>

                    <p>Complexul Naţional Muzeal ASTRA este obligat să comunice măsurile luate, precum şi, dacă este
                        cazul, numele terţului căruia i-au fost dezvăluite datele cu caracter personal referitoare la
                        persoana vizată, in termen de 30 zile de la data primirii cererii.</p>

                    <br/>

                    <p><span class="astra-font-bold">Dreptul de opoziţie</span>: persoana vizată are dreptul de a se
                        opune în orice moment, printr-o cerere întocmită în forma scrisă, datată şi semnată, din motive
                        întemeiate şi legitime legate de situaţia sa particulară, ca datele care o vizează să facă
                        obiectul unei prelucrări, cu excepţia cazurilor în care există dispoziţii legale contrare. În
                        caz de opoziţie justificată, prelucrarea nu mai poate viza datele in cauză.
                    </p>

                    <p> Persoana vizată are dreptul de a se opune in orice moment, in mod gratuit şi fără nici o
                        justificare, ca datele care o vizează să fie prelucrate in scop de marketing direct, in numele
                        operatorului sau al unui terţ, sau să fie dezvăluite unor terţi într-un asemenea scop. </p>
                    <p>Complexul Naţional Muzeal ASTRA este obligat să comunice persoanei vizate măsurile luate, precum
                        şi, dacă este cazul, numele terţului căruia i-au fost dezvăluite datele cu caracter personal
                        referitoare la persoana vizată, in termen de 30 zile de la data primirii cererii.</p>

                    <br/>
                    <p><span class="astra-font-bold">
                        Dreptul de a vă adresa Autorităţii Naţionale de Supraveghere a Prelucrării Datelor cu
                        Caracter
                        Personal sau justiţiei</span>, pentru apărarea oricăror drepturi garantate de RGPD/2016, care
                        v-au
                        fost încălcate.
                    </p>

                    <br/>
                    <p class="astra-font-bold"> Cum vă puteți exercita drepturile?</p>
                    <p>Acordăm o importanță deosebită protecției datelor cu caracter personal și, prin urmare, avem
                        personal dedicat serviciilor de asistență pentru clienți care vă gestionează solicitările în
                        legătură cu drepturile dvs. menționate mai sus. Personalul nostru vă stă la dispoziție
                        întotdeauna la adresa <a href="mailto: dpo@muzeulastra.com">dpo@muzeulastra.com.</a></p>

                    <br/>
                    <p class="astra-font-bold"> Dreptul de a depune o plângere la o autoritate de supraveghere:</p>
                    <p> Dacă sunteți de părere că Complexul Naţional Muzeal ASTRA vă prelucrează datele cu caracter
                        personal într-un mod incorect, ne puteți contacta. De asemenea, aveți dreptul să adresați o
                        plângere
                        <span class="astra-font-bold">Autorităţii Naţionale de Supraveghere a Prelucrării Datelor cu
                            Caracter Personal sau justiţiei, pentru apărarea oricăror drepturi garantate de RGPD/2016
                        </span>, care v-au fost încălcate.
                    </p>

                    <br/>
                    <p class="astra-font-bold">Actualizări ale Notificării de confidențialitate:</p>
                    <p> S-ar putea să fie nevoie să ne actualizăm Notificarea de confidențialitate. Cea mai recentă
                        versiune
                        a Notificării de confidențialitate este disponibilă în permanență pe site-ul nostru. Vom
                        comunica
                        orice schimbări semnificative ale Notificării de confidențialitate, de exemplu scopul pentru
                        care
                        folosim datele dvs. cu caracter personal, identitatea operatorului sau drepturile dumneavoastră
                    </p>

                    <br/>

                    <p class="astra-font-bold">Date de contact</p>
                    <p>COMPLEXUL NATIONAL MUZEAL ASTRA SIBIU, cu sediul în Sibiu, str. Pădurea Dumbrava nr. 16</p>
                    <p>telefon 0269/202400</p>
                    <p>CUI 4406304</p>
                    <p><a (click)="onNavigate()" target="_blank"> www.muzeulastra.ro</a></p>
                    <p>email: <a href="mailto: office@muzeulastra.ro">office@muzeulastra.ro</a></p>

                </div>
            </div>
            <div class="card-footer">
                <div class="stats margin-15">
                    <a routerLink="/">{{'SUCCESS.BACK_MESSAGE' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
