export class OrderDetails {
    private orderId: string;
    private status: string;
    private title: string;
    private message: string;


    constructor(details: object = DEFAULT_VALUES) {
        Object.assign(this, details);
    }

    get _orderId(): string {
        return this.orderId;
    }

    set _orderId(value: string) {
        this.orderId = value;
    }

    get _status(): string {
        return this.status;
    }

    set _status(value: string) {
        this.status = value;
    }

    get _title(): string {
        return this.title;
    }

    set _title(value: string) {
        this.title = value;
    }

    get _message(): string {
        return this.message;
    }

    set _message(value: string) {
        this.message = value;
    }
}

const DEFAULT_VALUES = {
    orderId: null,
    status: null,
    title: null,
    message: null,
};
