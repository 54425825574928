<div class="row" fxShow>
    <div class="col-12 text-center">
        <div class="row">
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 footer-details">
                <div class="row footer-details__header c-pointer" (click)="goToMainSite()">{{'APP.MUSEUM' | translate}}
                </div>
                <div class="row c-pointer" (click)="goToTerms()">{{'APP.TERMS' | translate}}</div>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 footer-details">
                <div class="row footer-details__header">&nbsp;</div>
                <div class="row">
                    <img src="assets/images/sal-logo.png"
                         alt="SAL" (click)="goToSAL()"
                         class="astra_img"/>
                    <img src="assets/images/sol-logo.png"
                         alt="SOL" (click)="goToSOL()"
                         class="astra_img"/>
                </div>
            </div>
            <div class="col-4 col-sm-4 col-md-4 col-lg-4 footer-details">
                <div class="row footer-details__header">{{'APP.PARTNERS' | translate}}</div>

                <div class="row">
                    <img src="assets/images/netopia-logo.jpg"
                         alt="Netopia" (click)="goToNetopia()"
                         class="astra_img"/>
                </div>
            </div>
        </div>
        <div class="row footer-info">
            <span class="year footer-align">
                {{'APP.FOOTER_COPYRIGHT' | translate}} {{year}} {{'APP.FOOTER_DETAILS' | translate}}</span>
        </div>
    </div>
</div>
