<div class="row asp-card-margin">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-icon">
                <div class="card-icon" routerLink="/dashboard">
                    <img class="img-size" src="assets/images/404.png" alt="not-found">
                </div>
                <h1 class="card-title asp-card-title">
                    {{'ERRORS.MESSAGE' | translate}}</h1>
                <p></p>
                <h2 class="card-category">{{'ERRORS.DETAILS' | translate}}</h2>

            </div>
            <div class="card-footer">
                <div class="stats margin-15">
                    <a routerLink="/">{{'SUCCESS.BACK_MESSAGE' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
