export class SharedConstants {
    public static readonly EN = 'en';
    public static readonly RO = 'ro';
    public static readonly LOCALE = 'locale';
    public static readonly LANGUAGES = ['EN', 'RO'];
    public static readonly SNACKBAR_TIME = 5000;
    public static readonly SUBSCRIPTION = 'subscription';
    public static readonly FAMILY_SUBSCRIPTION_ID = 5;
    public static readonly STUDENT_SUBSCRIPTION = 6;
    public static readonly STUDENT_TICKET = 3;
    public static readonly ADULT_SUBSCRIPTION = 4;
    public static readonly ADULT_TICKET = 1;
    public static readonly SENIOR_SUBSCRIPTION =  2;
    public static readonly SENIOR_TICKET = 2 ;
    public static readonly TICKET= 'ticket';
    public static readonly EMAIL_PATTERN = '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    public static readonly PHONE_PATTERN = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$';
    public static readonly DEFAULT_COUNTRY = 'Romania';
    public static readonly PARAMS = 'params';
}
