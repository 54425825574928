import {Ticket} from 'src/app/shared/models/ticket';
import {User} from 'src/app/shared/models/user';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';

export class SelectedTicket {
    private type: number;
    private validFrom: Date;
    private validTo: Date;
    private ticket: Ticket;
    private person: User;
    private adults: number;
    private children: number;
    private entrances: number;

    constructor(sTicket: object = DEFAULT_VALUES) {
        Object.assign(this, sTicket);
        sTicket[SharedConstants.TICKET] = new Ticket(sTicket[SharedConstants.TICKET]);
    }

    get _type(): number {
        return this.type;
    }

    set _type(value: number) {
        this.type = value;
    }

    get _validFrom(): Date {
        return this.validFrom;
    }

    set _validFrom(value: Date) {
        this.validFrom = value;
    }

    get _validTo(): Date {
        return this.validTo;
    }

    set _validTo(value: Date) {
        this.validTo = value;
    }

    get _ticket(): Ticket {
        return this.ticket;
    }

    set _ticket(value: Ticket) {
        this.ticket = value;
    }

    get _person(): User {
        return this.person;
    }

    set _person(value: User) {
        this.person = value;
    }

    get _adults(): number {
        return this.adults;
    }

    set _adults(value: number) {
        this.adults = value;
    }

    get _children(): number {
        return this.children;
    }

    set _children(value: number) {
        this.children = value;
    }

    get _entrances(): number {
        return this.entrances;
    }

    set _entrances(value: number) {
        this.entrances = value;
    }


}

const DEFAULT_VALUES = {
    type: null,
    validFrom: null,
    validTo: null,
    ticket: null,
    person: null,
    children: 1,
    adults: 2,
    entrances: 0,
};
