import {SharedConstants} from 'src/app/shared/constants/shared-constants';

const DEFAULT_VALUES = {
    en: '',
    ro: '',
};

export class CustomI18n {
    private en: string;
    private ro: string;

    constructor(language: object = DEFAULT_VALUES) {
        Object.assign(this, language);
    }

    get _en(): string {
        return this.en;
    }

    set _en(value: string) {
        this.en = value;
    }

    get _ro(): string {
        return this.ro;
    }

    set _ro(value: string) {
        this.ro = value;
    }

    get _current(): string {
        let value;
        const locale = CustomI18n._locale;
        switch (locale) {
            case SharedConstants.EN: value = this.en; break;
            case SharedConstants.RO: value = this.ro; break;
            default: break;
        }

        return value;
    }

    static get _locale(): string {
        return localStorage.getItem(SharedConstants.LOCALE) || SharedConstants.RO;
    }
}
