import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {OrderDetails} from 'src/app/shared/models/order-details';
import {ErrorService} from 'src/app/shared/services/error.service';
import {Observable} from 'rxjs/Rx';
import {Ticket} from 'src/app/shared/models/ticket';
import {environment} from 'src/environments/environment';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {catchError, map} from 'rxjs/internal/operators';
import {APIService} from 'src/app/shared/services/api.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(private apiService: APIService,
                private httpClient: HttpClient,
                private router: Router,
                private errorService: ErrorService) {
    }

    public getTicketTypes(isSubscription: string): Observable<Ticket[]> {
        const requestUrl = this.apiService.resolveUrl(environment.getTicketTypesListEndpoint);

        const params = new HttpParams().set(SharedConstants.SUBSCRIPTION, isSubscription);

        return this.httpClient.get(requestUrl, {params, withCredentials: false})
            .pipe(
                catchError((error) => {
                    this.router.navigate(['/not-found']);

                    return error;
                }))
            .map((response: Ticket[]) => {

                const tickets = [];
                response.map((item: Ticket) => tickets.push(new Ticket(item)));

                return tickets;
            });
    }


    public executePayment(selectedTickets): Observable<any> {
        const requestUrl = this.apiService.resolveUrl(environment.paymentGatewayEndpoint);

        return this.httpClient.post(requestUrl, selectedTickets, {withCredentials: false})
            .pipe(
                catchError((error) => {
                    this.router.navigate(['/not-found']);

                    return error;

                }))
            .map((response) => {
                this.submitForm(response);
            });
    }


    private submitForm(data): void {
        const form = window.document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', data.url);

        // Add all the data to be posted as Hidden elements
        form.appendChild(this.createHiddenElement('data', data.formData.data));
        form.appendChild(this.createHiddenElement('env_key', data.formData.env_key));

        window.document.body.appendChild(form);
        form.submit();
    }

    private createHiddenElement(name: string, value: string): HTMLInputElement {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('name', name);
        hiddenField.setAttribute('value', value);
        hiddenField.setAttribute('type', 'hidden');

        return hiddenField;
    }

    public getAgeGroups(): Observable<string[]> {
        const requestUrl = this.apiService.resolveUrl(environment.ageGroupDictionary);

        return this.httpClient.get(requestUrl, {withCredentials: false})
            .pipe(
                catchError((error) => {
                    this.router.navigate(['/not-found']);

                    return error;
                }))
            .map((response: string[]) => {
                const list = [];
                response.map((item) => list.push(item));

                return list;
            });
    }

    public getCountries(): Observable<string[]> {
        const requestUrl = this.apiService.resolveUrl(environment.countriesDictionary);

        return this.httpClient.get(requestUrl, {withCredentials: false})
            .pipe(
                catchError((error) => {
                    this.router.navigate(['/not-found']);

                    return error;
                }))
            .map((response: string[]) => {
                const list = [];
                response.map((item) => list.push(item));

                return list;
            });
    }

    public getRegions(): Observable<string[]> {
        const requestUrl = this.apiService.resolveUrl(environment.regionsDictionary);

        return this.httpClient.get(requestUrl, {withCredentials: false})
            .pipe(
                catchError((error) => {
                    this.router.navigate(['/not-found']);

                    return error;
                }))
            .map((response: string[]) => {
                const list = [];
                response.map((item) => list.push(item));

                return list;
            });
    }

    public getOrderInfo(orderId): Observable<OrderDetails>{
        const requestUrl = this.apiService.resolveUrl(`${environment.orderInfo}${orderId}${environment.orderStatus}`);

        return this.httpClient.get(requestUrl, {withCredentials: false})
            .pipe(
                catchError((error) => {
                    this.router.navigate(['/not-found']);

                    return error;
                }))
            .map((response: OrderDetails) => {

                return new OrderDetails(response);
            });
    }
}


