import {Inject, Injectable} from '@angular/core';

@Injectable()
export class APIService {
    constructor(@Inject('BASE_URL') private baseUrl: string) {
    }

    public resolveUrl(path: string): string {

        return this.baseUrl + path;
    }
}
