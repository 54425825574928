import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {StorageService} from 'src/app/shared/services/storage.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public year: any;

    constructor(private storageService: StorageService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.year = new Date().getFullYear();
    }

    public goToTerms(): void {
        this.router.navigate(['/terms-and-conditions']);
    }

    public goToNetopia(): void {
        window.open('http://www.netopia.ro/', '_blank');
    }

    public goToSOL(): void {
        const currentLanguage = this.storageService.get(SharedConstants.LOCALE) || SharedConstants.RO;
        const lang = currentLanguage.toUpperCase();
        window.open(`https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=${lang}`, '_blank');
    }

    public goToSAL(): void {
        window.open('https://anpc.ro/ce-este-sal/', '_blank');
    }

    public goToMainSite(): void {
        window.open('https://muzeulastra.ro/', '_blank');
    }

}
