import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SelectedTicket} from 'src/app/shared/models/selected-ticket';

@Component({
    selector: 'app-ticket-data',
    templateUrl: './ticket-data.component.html',
    styleUrls: ['./ticket-data.component.scss']
})
export class TicketDataComponent implements OnInit {
    @Input() public form: FormGroup;
    @Input() public selected: SelectedTicket[];
    @Input() public isTicket: boolean;
    @Input() public isSubscription: boolean;
    @Output() public notify = new EventEmitter<any>();

    @ViewChild('personalDetailsForm', {static: true}) public personalDetailsForm;

    constructor() {
    }

    public ngOnInit(): void {
    }

    public count(tickets): number {
        let total = 0;

        if (tickets && tickets.length !== 0) {
            tickets.forEach((t) => total = total + t._ticket._price);
        }

        return total;
    }

    public onNotifyWithPersonalDetails(event): void {
        return;
    }
}
