import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {StorageService} from 'src/app/shared/services/storage.service';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public title = 'astra-portal';
    public unsubscribe$: Subject<void> = new Subject<void>();
    public showBanner: boolean;

    constructor(private translate: TranslateService,
                private storageService: StorageService) {
    }

    public ngOnInit(): void {
        const userLanguage = this.storageService.get(SharedConstants.LOCALE) || 'RO';
        this.translate.setDefaultLang(userLanguage.toLowerCase());
        this.showBanner = environment.production;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
