<div class="row d-flex justify-content-between" style="padding-top: 20px">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="card card-stats">
            <div class="card-header card-header-danger card-header-icon">
                <div class="card-icon" routerLink="/dashboard">
                    <img src="/assets/images/logo.png" alt="astra-mill">
                </div>

                <h1 class="card-title asp-card-title">{{order._title}}</h1>

                <div class="row card-category order-details">
                    <div class="col-12">{{order._message}}</div>
                    <div class="col-12">{{'SUCCESS.DETAILS' | translate}}</div>
                    <div class="col-12">
                        <ul>
                            <li>{{'SUCCESS.ORDER_ID' | translate}}: &nbsp;{{order._orderId}}</li>
                            <li>{{'SUCCESS.STATUS' | translate}}: &nbsp;
                                <span
                                    *ngIf="order._status==='CONFIRMED'">{{'SUCCESS.STATUS_CONFIRMED' | translate}}</span>
                                <span
                                    *ngIf="order._status==='REJECTED'">{{'SUCCESS.STATUS_REJECTED' | translate}}</span>
                                <span *ngIf="order._status==='WAITING'">{{'SUCCESS.STATUS_WAITING' | translate}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="stats margin-15">
                        <a routerLink="/">{{'SUCCESS.BACK_MESSAGE' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
