/* tslint:disable */

import {ErrorHandler, Injectable} from '@angular/core';
import {throwError} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ErrorService implements ErrorHandler {

    constructor() {
    }

    public handleError(error: Response) {
        switch (error.status) {
            case 400:
                return throwError(error);
            default:
                console.log(error);
        }
    }
}
