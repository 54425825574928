// tslint:disable:no-any

import {from, Observable} from 'rxjs';
import {TranslateLoader} from '@ngx-translate/core';

export class WebpackTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
        return from(import(`../assets/i18n/${lang}.json`));
    }
}
