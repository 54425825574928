const DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    studentCardNr: null,
    ageGroup: '',
    country: '',
    region: '',
    address: '',
    gdpr: false
};


export class User {
    private firstName: string;
    private lastName: string;
    private phone: string;
    private email: string;
    private studentCardNr: string;
    private ageGroup: string;
    private country: string;
    private region: string;
    private address: string;
    private gdpr: boolean;

    constructor(user?: User) {
        Object.assign(this, user || DEFAULT_VALUES);
    }

    get _firstName(): string {
        return this.firstName;
    }

    set _firstName(value: string) {
        this.firstName = this.capitalize(value);
    }

    get _lastName(): string {
        return this.lastName;
    }

    set _lastName(value: string) {
        this.lastName = this.capitalize(value);
    }

    get _phone(): string {
        return this.phone;
    }

    set _phone(value: string) {
        this.phone = value;
    }

    get _email(): string {
        return this.email;
    }

    set _email(value: string) {
        this.email = value;
    }

    get _studentCardNr(): string {
        return this.studentCardNr;
    }

    set _studentCardNr(value: string) {
        this.studentCardNr = value;
    }

    get _ageGroup(): string {
        return this.ageGroup;
    }

    set _ageGroup(value: string) {
        this.ageGroup = value;
    }

    get _country(): string {
        return this.country;
    }

    set _country(value: string) {
        this.country = value;
    }

    get _region(): string {
        return this.region;
    }

    set _region(value: string) {
        this.region = value;
    }

    get _address(): string {
        return this.address;
    }

    set _address(value: string) {
        this.address = value;
    }

    get _gdpr(): boolean {
        return this.gdpr;
    }

    set _gdpr(value: boolean) {
        this.gdpr = value;
    }

    public capitalize(input) {
        return (!!input) ? input.split(' ').map(function (wrd) {
            return wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase();
        }).join(' ') : '';
    }
}

