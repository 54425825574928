import {User} from 'src/app/shared/models/user';
import {SelectedTicket} from 'src/app/shared/models/selected-ticket';

export class PaymentDetails {
    private tickets: SelectedTicket[];
    private person: User;


    constructor(details: object = DEFAULT_VALUES) {
        Object.assign(this, details);
    }

    get _tickets(): SelectedTicket[] {
        return this.tickets;
    }

    set _tickets(value: SelectedTicket[]) {
        this.tickets = value;
    }

    get _person(): User {
        return this.person;
    }

    set _person(value: User) {
        this.person = value;
    }
}

const DEFAULT_VALUES = {
    tickets: null,
    person: null,
};
