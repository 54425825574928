// tslint:disable:no-any

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {SelectedTicket} from 'src/app/shared/models/selected-ticket';
import {PaymentDetails} from 'src/app/shared/models/payment-details';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {takeUntil} from 'rxjs/internal/operators';
import {EventService} from 'src/app/shared/services/event.service';
import {Subject} from 'rxjs/Rx';
import * as _ from 'lodash';

@Component({
    selector: 'app-payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
    @Input() public form: FormGroup;
    @Input() public selected: SelectedTicket[];
    @Input() public isTicket: boolean;
    @Input() public isSubscription: boolean;
    @Input() public data: PaymentDetails;
    @Output() public notify = new EventEmitter<any>();
    @Input() public keyupSubmit: boolean;
    public emailPattern: string;
    public phonePattern: string;

    private unsubscribe$ = new Subject();

    constructor(private eventService: EventService,
                private router: Router) {

        this.eventService.formEvent$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((event) => {
                if (event) {
                    this.onSubmit(this.form.value);
                }
            });

    }

    public ngOnInit(): void {
        this.emailPattern = SharedConstants.EMAIL_PATTERN;
        this.phonePattern = SharedConstants.PHONE_PATTERN;
    }

    public count(tickets): number {
        let total = 0;

        if (tickets && tickets.length !== 0) {
            tickets.forEach((t) => total = total + t._ticket._price);
        }

        return total;
    }

    public onPaste(event): void {
        event.preventDefault();
    }

    public onKeyDown(event) {
        return (event.ctrlKey || event.altKey
            || (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false)
            || (95 < event.keyCode && event.keyCode < 106)
            || (event.keyCode === 8) || (event.keyCode === 9)
            || (event.keyCode > 34 && event.keyCode < 40)
            || (event.keyCode === 46)
            || (event.keyCode === 187 && event.shiftKey === true));
    }


    public keyUpSubmit(form): void {
        if (this.keyupSubmit) {
            this.onSubmit(form);
        }
    }

    public onSubmit(form): void {
        if (!this.form.valid) {
            this.notify.emit(null);
        }

        this.notify.emit(form);
    }

    public groupByType(tickets): SelectedTicket[] {
        const result = _(tickets)
            .groupBy('type')
            .map(function (items, type) {
                return {
                    type: type,
                    count: items.length,
                    description: items[0].ticket._description,
                    price: items[0].ticket._price,
                    currency: items[0].ticket._currency
                };
            }).value();

        return result;
    }

    public goToTerms(): void {
        window.open(window.location.href + '/terms-and-conditions', '_blank');

    }
}
