import {CustomI18n} from 'src/app/shared/models/custom-i18n';

export class Ticket {
    private id: number;
    private code: string;
    private description: CustomI18n;
    private price: number;
    private currency: string;
    private group: boolean;
    private quantity: number;
    private minQuantity: number;
    private isStudent: boolean;
    private from: Date;
    private to: Date;
    private entrances: number;

    constructor(ticket?: Ticket) {
        Object.assign(this, ticket || DEFAULT_VALUES);

        if (ticket && ticket.description) {
            this.description = new CustomI18n(ticket.description);
        }
    }

    get _code(): string {
        return this.code;
    }

    set _code(value: string) {
        this.code = value;
    }

    get _description(): CustomI18n {
        return this.description;
    }

    set _description(value: CustomI18n) {
        this.description = value;
    }

    get _price(): number {
        return this.price;
    }

    set _price(value: number) {
        this.price = value;
    }

    get _currency(): string {
        return this.currency;
    }

    set _currency(value: string) {
        this.currency = value;
    }

    get _group(): boolean {
        return this.group;
    }

    set _group(value: boolean) {
        this.group = value;
    }

    get _quantity(): number {
        return this.quantity ? this.quantity : 0;
    }

    set _quantity(value: number) {
        this.quantity = value;
    }

    get _minQuantity(): number {
        return this.minQuantity;
    }

    set _minQuantity(value: number) {
        this.minQuantity = value;
    }

    get _isStudent(): boolean {
        return this.isStudent;
    }

    set _isStudent(value: boolean) {
        this.isStudent = value;
    }

    get _from(): Date {
        return this.from;
    }

    set _from(value: Date) {
        this.from = value;
    }

    get _to(): Date {
        return this.to;
    }

    set _to(value: Date) {
        this.to = value;
    }

    get _id(): number {
        return this.id;
    }

    set _id(value: number) {
        this.id = value;
    }

    get _entrances(): number {
        return this.entrances;
    }

    set _entrances(value: number) {
        this.entrances = value;
    }
}

const DEFAULT_VALUES = {
    id: null,
    code: '',
    description: new CustomI18n(),
    price: 0,
    currency: '',
    group: false,
    quantity: 1,
    minQuantity: 1,
    isStudent: false,
    from: null,
    to: null,
    entrances: 0
};
