<div class="navbar-fixed-top toolbar toolbar-shadow"
     role="navigation"
     [style.position]="isHeaderSticky ? 'fixed' : 'inherit'"
     [class.mat-elevation-z4]="isHeaderSticky">
    <mat-toolbar class="toolbar-background"
                 style="display: flex; flex-direction: row; align-items: center">
        <div class="logo-bg navbar-brand pointer-cursor" (click)="goToHomePage()">
            <span class="branding"><img src="assets/images/logo.png" class="toolbar-logo"/></span>
        </div>

        <div class="spacer"></div>

        <div class="row text-right pointer-cursor asp-margin"
             fxLayout="row"
             fxLayoutAlign="start center">
            <span *ngFor="let lng of languages" (click)="switchLanguage(lng)"
                  class="app-title-btn">
                <img src="assets/images/en.png"
                     class="lng-img"
                     *ngIf="lng === lngEN.toUpperCase()"
                     [ngClass]="lng === selectedLanguage.toUpperCase() ? 'active-language': 'inactive-language'">
                <img src="assets/images/ro.png"
                     class="lng-img"
                     *ngIf="lng === lngRO.toUpperCase()"
                     [ngClass]="lng === selectedLanguage.toUpperCase() ? 'active-language': 'inactive-language'">
                <span
                    class="lng-label"
                    [ngClass]="lng === selectedLanguage.toUpperCase() ? 'active-text': 'inactive-text'"> {{lng}} </span>
            </span>
        </div>
    </mat-toolbar>
</div>
