import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedConstants} from 'src/app/shared/constants/shared-constants';
import {OrderDetails} from 'src/app/shared/models/order-details';
import {DataService} from 'src/app/shared/services/data.service';

@Component({
    selector: 'app-success-message',
    templateUrl: './success-message.component.html',
    styleUrls: ['./success-message.component.scss']
})
export class SuccessMessageComponent implements OnInit {
    public isSubscription: boolean;
    public isTicket: boolean;
    public orderId: any;
    public order: OrderDetails;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private service: DataService) {
        this.order =  new OrderDetails();

        this.route.queryParamMap.subscribe((value) => {
            const orderId = value[SharedConstants.PARAMS].orderId;
            this.getOrderInfo(orderId);
        });
    }

    public ngOnInit(): void {
        this.isSubscription = false;
        this.isTicket = false;

        if (this.router.url.toString().includes('abonamente')) {
            this.isSubscription = true;
        } else {
            this.isTicket = true;
        }
    }

    private getOrderInfo(orderId): void {

        this.service.getOrderInfo(orderId).subscribe((response) => {
            this.order =  response;
        });

    }
}
