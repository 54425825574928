export const environment = {
    production: true,
    environmentName: 'PROD',
    // baseUrl: 'https://astraqapay.iello.ro/api/',
    baseUrl: 'https://backend.muzeulastra.ro:8083/api/',
    general: {
        getLandingPageCards: '/assets/docs/landing-page-cards.json',
    },
    getTicketTypesListEndpoint: 'web/products',
    paymentGatewayEndpoint: 'web/payment',
    ageGroupDictionary: 'web/agegroups',
    countriesDictionary: 'web/countries',
    regionsDictionary: 'web/regions',
    orderInfo:  '/web/order/',
    orderStatus: '/status'
};
